import React, { useState, Suspense, useTransition } from 'react';

import { useLocation } from '@reach/router';
import { Link } from 'gatsby';

import { useTranslation } from 'react-i18next';

import Spinner from '@components/UI/Spinner';

const Counters = React.lazy(() => import('@pages/setting/counters'));
const Documentations = React.lazy(() =>
  import('@pages/setting/documentations')
);
const NewLeaves = React.lazy(() => import('@pages/setting/NewLeaves'));

function Settings() {
  const { t } = useTranslation();

  const [isPending, startTransition] = useTransition();

  const location = useLocation();

  const initialTabs = [
    {
      label: t('leaves'),
      name: '',
      content: <NewLeaves />,
      active: location.hash.replace('#', '') === '',
    },
    {
      label: t('counter'),
      name: 'counters',
      content: <Counters />,
      active: location.hash.replace('#', '') === 'counters',
    },
    {
      label: t('documentation'),
      name: 'documentation',
      content: <Documentations />,
      active: location.hash.replace('#', '') === 'documentation',
    },
    // TODO
    // {
    //   label: 'refonnte congé',
    //   name: 'Refonce Conges',
    //   content: <NewLeaves />,
    //   active: location.hash.replace('#', '') === 'newLeaves',
    // },
  ];

  const [tabs, setTabs] = useState(initialTabs);

  // set the tab active
  const setActive = (tab) => {
    startTransition(() => {
      setTabs((prevState) => {
        return prevState.map((t) => {
          return {
            ...t,
            active: t.name === tab,
          };
        });
      });
    });
  };

  return (
    <div className=''>
      <h1 className={'mb-6 text-2xl'}>{t('setting')}</h1>
      <div className='rounded-md flex flex-col gap-4'>
        <nav className='flex w-fit font-lato' aria-label='Tabs'>
          {tabs.map((tab, index) => (
            <Link
              key={tab.name}
              to={`#${tab.name}`}
              onClick={() => setActive(tab.name)}
              className={`${
                tab.active
                  ? 'border-primary1 text-primary1 hover:text-primary1/80 border-primary1/80'
                  : 'border-gray-700/50 text-gray-500 hover:text-gray-700 border-[#F2F2F2]'
              }
                   px-2 border-b-2 hover:border-primary1/80 transition duration-100 ease-in text-lg`}
            >
              <span>{tab.label}</span>
            </Link>
          ))}
        </nav>

        <Suspense fallback={<Spinner />}>
          <div className={'mt-4'}>
            {tabs.map((tab) => tab.active && tab.content)}
          </div>
        </Suspense>
      </div>
    </div>
  );
}

export default Settings;
