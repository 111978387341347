import React from 'react';

import Lottie from 'lottie-react';

import loader from '@assets/lottie/loader';

const Spinner = (props) => {
  const style = {
    height: props.height ?? 500,
    width: props.width ?? 500,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  return (
    <div className='flex items-center justify-center'>
      <Lottie animationData={loader} loop={true} style={style} />
    </div>
  );
};

export default Spinner;
